


















































































































// Libs
import { Vue, Component, Watch, Prop } from "vue-property-decorator";

// Components
import NavGroupComponent, { INav } from "./nav-group.component.vue";
import NavListComponent from "./nav-list.component.vue";

@Component({ components: { NavGroupComponent, NavListComponent } })
export default class HeaderComponent extends Vue {
  public get navGroupStyleDirective() {
    return {
      margin: "0px",
      padding: "0px",
      listStyle: "none",
      flex: "1",
      display:
        ["xxl", "xl", "lg"].includes((this as any).$mq) === true
          ? "flex"
          : "none"
    };
  }

  public a: boolean = false;
  @Prop({ default: 0 })
  public scrollY!: number;

  @Watch("a")
  private onAChange = (value: boolean) => {
    this.overflowBody(value);
  };

  @Watch("$mq")
  private onMqChange = (value: string) => {
    if (["xxl", "xl", "lg"].includes(value)) {
      this.overflowBody(false);
    } else if (this.a === true) {
      // TODO Always seem to be false
      this.overflowBody(true);
    }
  };

  public leftMenu: INav[] = [];
  public rightMenu: INav[] = [];
  public get nav(): INav[] {
    return [...this.leftMenu, ...this.rightMenu];
  }

  private overflowBody(shouldHideOverflow: boolean) {
    const body = document.querySelector("body");
    if (body === null) {
      return;
    }
    body.style.overflowY = shouldHideOverflow === true ? "hidden" : "initial";
  }

  public created() {
    this.leftMenu = [
      { title: this.$t("menu.home"), route: { name: "home" } },
      { title: this.$t("menu.landscaping"), route: { name: "landscaping" } },
      { title: this.$t("menu.drainage"), route: { name: "drainage" } },
      { title: this.$t("menu.shipment"), route: { name: "shipment" } }
    ];
    this.rightMenu = [
      { title: this.$t("menu.road"), route: { name: "road" } },
      { title: this.$t("menu.suez"), route: { name: "suez" } },
      { title: this.$t("menu.references"), route: { name: "references" } },
      { title: this.$t("menu.contact"), route: { name: "contact" } }
    ];
  }

  public listClick() {
    this.a = false;
  }
}
