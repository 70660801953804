























// Libs
import { Vue, Component, Watch } from "vue-property-decorator";
import { Route } from "vue-router";

// Components
import BarComponent from "./bar.component.vue";
import ContactComponent from "./contact.component.vue";
import DrainageComponent from "./drainage.component.vue";
import FooterComponent from "./footer.component.vue";
import HeaderComponent from "./header.component.vue";
import HomeComponent from "./home.component.vue";
import LandscapingComponent from "./landscaping.component.vue";
import ReferencesComponent from "./references.component.vue";
import RoadComponent from "./road.component.vue";
import ShipmentComponent from "./shipment.component.vue";
import SuezComponent from "./suez.component.vue";

@Component({
  components: {
    BarComponent,
    ContactComponent,
    DrainageComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LandscapingComponent,
    ReferencesComponent,
    RoadComponent,
    ShipmentComponent,
    SuezComponent
  }
})
export default class IndexComponent extends Vue {
  public scrollY: number = 0;

  @Watch("route")
  private onRouteChange(route: Route) {
    if ("programatic" in this.$route.params === false) {
      this.scrollIntoView(`${this.$route.name}`);
    }
  }

  public get route(): Route {
    return this.$route;
  }

  public created() {
    this.scrollY = window.scrollY;
    window.addEventListener("scroll", (e: any) => {
      this.scrollY = window.scrollY;
    });
  }

  public mounted() {
    const routeName = `${this.route.name}`;
    this.$nextTick(() => {
      this.scrollIntoView(routeName);
    });
  }

  public routerNativeClick(route: Route) {
    console.log(route.name);
    if (route.name !== undefined && route.name !== null) {
      this.scrollIntoView(route.name);
    }
  }

  private scrollIntoView(routeName: string) {
    const element: Element | null = document.querySelector(
      `a[name=${routeName}]`
    );
    if (element !== null) {
      element.scrollIntoView();
    }
  }

  public inView(routeName: string) {
    if (this.$route.name !== routeName && this.$route.name !== "index") {
      this.$router.push({ name: routeName, params: { programatic: "true" } });
    }
  }
}
