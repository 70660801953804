













import { Vue, Component, Prop } from "vue-property-decorator";
@Component
export default class PixelComponent extends Vue {
  @Prop({ default: "top" })
  public position!: string;
  @Prop({ default: false })
  public isTopOfPage!: boolean;
  @Prop({ default: "" })
  public name!: string;
  @Prop({ default: "" })
  public routeName!: string;

  public get computedStyle(): any {
    if (this.isTopOfPage === true) {
      if (this.position === "screen_bottom") {
        return { top: "100vh" };
      } else if (this.position === "bottom") {
        return { bottom: "0px" };
      }
    } else {
      if (this.position === "screen_bottom") {
        return {
          top: `calc((var(${(() => {
            switch ((this as any).$mq) {
              case "xxl":
                return "--header-extra-large-height";
              case "xl":
              case "lg":
                return "--header-large-height";
            }
            return "--header-small-height";
          })()}) * -1) + 100vh)`
        };
      } else if (this.position === "bottom") {
        return {
          bottom: `calc(0px + var(${(() => {
            switch ((this as any).$mq) {
              case "xxl":
                return "--header-extra-large-height";
              case "xl":
              case "lg":
                return "--header-large-height";
            }
            return "--header-small-height";
          })()}))`
        };
      } else {
        return {
          top: `calc((var(${(() => {
            switch ((this as any).$mq) {
              case "xxl":
                return "--header-extra-large-height";
              case "xl":
              case "lg":
                return "--header-large-height";
            }
            return "--header-small-height";
          })()}) * -1))`
        };
      }
    }

    return { top: "0px" };
  }
}
