import VueRouter, { NavigationGuardNext, Route } from "vue-router";

// Middlewares
import { default as middlewares, guard } from "./middlewares";

export const router = new VueRouter({
  mode: "history",
  routes: [
    { path: "/", name: "index", beforeEnter: (to: Route, from: Route, next: NavigationGuardNext) => { next({ name: "home" }) } },
    { path: "/home", name: "home" },
    { path: "/landscaping", name: "landscaping" },
    { path: "/drainage", name: "drainage" },
    { path: "/shipment", name: "shipment" },
    { path: "/road", name: "road" },
    { path: "/suez", name: "suez" },
    { path: "/references", name: "references" },
    { path: "/contact", name: "contact" },
    { path: "*", beforeEnter: guard([middlewares.invalidRoute]) },
  ],
  scrollBehavior(to: Route, from: Route, savedPosition?: { x: number, y: number }) {
    if (savedPosition !== undefined) {
      return savedPosition;
    }
    if (to.hash) {
      return {
        selector: to.hash,
      }
    }
  }
});