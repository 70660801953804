














































// Libs
import { Vue, Component, Prop } from "vue-property-decorator";
import { Location, Route } from "vue-router";

export interface INav {
  title: string;
  route: Location;
}

@Component
export default class NavGroupComponent extends Vue {
  @Prop({ default: [] })
  public nav!: INav[];
}
