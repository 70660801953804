


















// Libs
import { Vue, Component, Prop } from "vue-property-decorator";

// Components
import PixelComponent from "./pixel.component.vue";

@Component({ components: { PixelComponent } })
export default class ThreePixelsComponent extends Vue {
  @Prop({ default: "" })
  public name!: string;
  @Prop({ default: false })
  public isTopOfPage!: boolean;
}
