

















// Libs
import { Vue, Component, Prop } from "vue-property-decorator";

// Components
import { INav } from "./nav-group.component.vue";

@Component
export default class NavListComponent extends Vue {
  @Prop({ default: [] })
  public nav!: INav[];

  public clicker() {
    alert("efs");
  }
}
