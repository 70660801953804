


























































import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class CarouselComponent extends Vue {
  @Prop({ default: [] })
  public items!: any[];

  public transitionDuration: number = 300;

  private transitionInMotion: boolean = false;
  private currentIndex: number = 0;

  public getPosStyle(index: number) {
    if (index === this.currentIndex) {
      // Index of image is the one to display
      return {
        left: "0px",
        opacity: "1",
      };
    } else if (
      (this.currentIndex !== 0 && index == this.currentIndex - 1) ||
      (this.currentIndex === 0 && index === this.items.length - 1)
    ) {
      // Index of image should be positionend left of the one on display
      return {
        left: "-100%",
        opacity: "0",
      };
    } else if (
      (this.currentIndex !== this.items.length - 1 &&
        index === this.currentIndex + 1) ||
      (this.currentIndex === this.items.length - 1 && index === 0)
    ) {
      // Index of image should be positioned right of the one on display
      return {
        left: "100%",
        opacity: "0",
      };
    }
    return { display: "none" };
  }

  public previous() {
    if (this.transitionInMotion === true) {
      return;
    }
    this.transitionInMotion = true;
    setTimeout(
      () => (this.transitionInMotion = false),
      this.transitionDuration
    );
    if (this.currentIndex === 0) {
      this.currentIndex = this.items.length - 1;
    } else {
      this.currentIndex = this.currentIndex - 1;
    }
  }
  public next() {
    if (this.transitionInMotion === true) {
      return;
    }

    this.transitionInMotion = true;
    setTimeout(
      () => (this.transitionInMotion = false),
      this.transitionDuration
    );

    if (this.currentIndex === this.items.length - 1) {
      this.currentIndex = 0;
    } else {
      this.currentIndex = this.currentIndex + 1;
    }
  }
}
