








































































































import { Vue, Component } from "vue-property-decorator";

import imageUrls from "../../resources/img/references/*.jpg";

import ContentComponent from "./content.component.vue";
import ThreePixelsComponent from "./three-pixels.component.vue";
import CarouselComponent from "./carousel.component.vue";

@Component({
  components: { ContentComponent, ThreePixelsComponent, CarouselComponent },
})
export default class ReferencesComponent extends Vue {
  public referenceImages: { [key: string]: string } = imageUrls;
  public imageList: any = [
    {
      url: this.referenceImages[1],
      text: "Montering av L stöd som tomtgräns",
    },
    {
      url: this.referenceImages[2],
      text: "Färdigarmerad grund i Glanshammar",
    },
    {
      url: this.referenceImages[3],
      text: "Singling för hus grund i Glanshammar",
    },
    {
      url: this.referenceImages[4],
      text: "Singling för bostadsrätter Mosås",
    },
    {
      url: this.referenceImages[5],
      text: "Färdig gjuten platta i Glanshammar",
    },
    {
      url: this.referenceImages[6],
      text: "Färdig grund till Älvsbyhus",
    },
    {
      url: this.referenceImages[7],
      text: "Färdig stenläggning på garage infart och gångar till hus",
    },
    {
      url: this.referenceImages[8],
      text: "Tippning av liftdumperbil på Suez",
    },
  ];

  public referenceTexts: string[] = [
    "Montering av L stöd som tomtgräns",
    "Färdigarmerad grund i Glanshammar",
    "Singling för hus grund i Glanshammar",
    "Singling för bostadsrätter Mosås",
    "Färdig gjuten platta i Glanshammar",
    "Färdig grund till Älvsbyhus",
    "Färdig stenläggning på garage infart och gångar till hus",
    "Tippning av liftdumperbil på Suez",
  ];
}
