import { NavigationGuard, RawLocation, Route } from "vue-router";
export type navigationGuardNext = (to?: RawLocation | false | ((vm: Vue) => any) | void) => void;

export default {
  // Redirect to start page if route is not defined
  invalidRoute: ((to, from, next) => {
    if (to.name) {
      next();
    } else {
      next({ name: "index" });
    }
  }) as NavigationGuard,
};

export const guard = (guards: NavigationGuard[]) => {
  return (from: Route, to: Route, next: navigationGuardNext) => {
    operate(guards, from, to, next, 0);
  };
};

const operate = (guards: NavigationGuard[], from: Route, to: Route, lastNext: navigationGuardNext, i: number) => {
  const guard: NavigationGuard = guards[i];
  if (guards.length === i + 1) {
    guard(from, to, lastNext);
  } else {
    guard(from, to, (nextArg) => {
      switch (typeof (nextArg)) {
        case "undefined":
          operate(guards, from, to, lastNext, i + 1);
          break;
        case "object":
          lastNext(nextArg);
          break;
        case "boolean":
          lastNext(nextArg);
          break;
        case "string":
          lastNext(nextArg);
          break;
      }
    });
  }
};
