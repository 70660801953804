




































import { Vue, Component } from "vue-property-decorator";

import ContentComponent from "./content.component.vue";
import FadeInComponent from "./fade-in.component.vue";
import ThreePixelsComponent from "./three-pixels.component.vue";

@Component({
  components: { ContentComponent, ThreePixelsComponent, FadeInComponent }
})
export default class ContactComponent extends Vue {}
